.rc-color-picker-panel {
  border: 1px solid #ccc;
}
.rc-color-picker-panel-inner {
  border: none;
  box-shadow: none;
}
.rc-color-picker-panel-board-hsv {
  border-radius: 12px;
  outline: none;
}
.rc-color-picker-panel-board-value {
  border: none;
  border-radius: 12px;
}
.rc-color-picker-panel-board-saturation {
  border: none;
  border-radius: 12px;
}
.rc-color-picker-panel-ribbon {
  border-radius: 12px;
}
.rc-color-picker-panel-wrap-preview {
  border-radius: 12px;
}
.rc-color-picker-panel-preview span {
  border-radius: 12px;
}
.rc-color-picker-panel-preview input {
  border-radius: 12px;
}

.color-picker-button {
  height: 20px;
  margin-top: 15px;
}

.color-picker-input {
  height: 35px;
}
