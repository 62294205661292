.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.center {
  display: flex;
  justify-content: center;
}

.cell-warning {
  background-color: #f44336;
  color: white;
}

.editable-cell-value-wrap {
  border: 1px solid #52c41a !important;
  border-radius: 4px !important;
  min-height: 35px;
  min-width: 60px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
}
