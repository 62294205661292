.register-btn {
  margin-left: 5px;
  margin-top: 10px;
}

.structure-result {
}

.structure-result .ant-carousel {
  width: 40%;
}

.structure-actions {
  display: flex;
  margin-top: 10px;
  margin-left: 13px;
}

.structure-actions a {
  margin-top: 12px;
  margin-right: 9px;
}
