.additional-component-card-item {
  justify-content: flex-start;
  gap: 20px;
  padding-bottom: 20px;
}

.additional-component-card-item .card-item-field {
  flex-direction: column;
  justify-content: space-between;
}

.additional-component-card-item:first-child {
  margin-top: unset;
}

.additional-component-card-item:last-of-type {
}

.additional-component-card-value {
  justify-content: flex-start !important;
}

.additional-component-roles {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  height: 25px;
}

.additional-component-roles.detail {
  margin-top: 0 !important;
}

.additional-component-roles:last-child {
  margin-bottom: 10px;
}

.additional-component-roles .gx-text-success {
  text-align: right;
}

.additional-component-roles-wrapper {
  width: 100%;
  justify-content: flex-start;
}

.addtional-component-percentage-input {
  height: 25px;
  width: 100px !important;
}

.addtional-component-select-input {
  max-height: 25px !important;
  margin-right: 0 !important;
  min-width: 80px;
}

.ant-select-selection-item {
  font-size: 12px;
  padding-bottom: 5px;
}

.ant-form-item-explain-error {
  font-size: 11px;
  right: 0;
  position: absolute;
  white-space: nowrap;
}

.sortableHelper {
  z-index: 1000;
}
