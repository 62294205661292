.compound-detail-header-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.compound-detail-header-icon svg {
  height: 20px;
  width: 20px;
}
