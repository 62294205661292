.row-array-card-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.row-array-item {
  display: block;
  margin-top: 10px;
}

.row-array-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
  gap: 10px;
}

.row-array-item-wrapper {
  display: flex;
  justify-content: space-between;
}

.disable-preview ~ .ant-image-mask {
  display: none !important;
}

.ant-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
