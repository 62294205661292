.gx-profile-banner-avatar img {
  padding: 15px;
}

.explore-header-select-ds {
  color: #fff;
}

.gx-profile-setting {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gx-link__activeTab {
  color: #fa8c15 !important;
}

.explore-page__checkboxGroup .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #08979c;
  border-color: #08979c;
}

.searchOps__label .ant-form-item-label > label {
  color: #f5f5f5 !important;
}

.explorePage__tabs .ant-tabs-tab {
  color: #f5f5f5 !important;
}

.explorePage__tabs .ant-tabs-tab-active,
.explorePage__tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fa8c15 !important;
}

.ant-tabs-ink-bar {
  display: none !important;
}

.ant-tabs-nav::before {
  display: none;
}

.searchOps__label .ant-switch-checked {
  background-color: #08979c !important;
}

.explorePage__datasources label {
  color: #f5f5f5;
  font-weight: 400 !important;
  font-size: 13px;
}
