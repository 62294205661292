.processTable__card {
  background-color: #fff !important;
  min-width: 23vw;
  max-height: 130px;
  position: relative;
  margin-bottom: 10px;
}

.processTable__row {
  max-height: unset;
  margin-bottom: 10px;
  border: 1px solid #eee !important;
}

@media (max-width: 1440px) {
  .processTable__card {
    max-height: unset;
  }
}

.processTable__rowContainer {
  column-gap: 50px;
}

.processTable__rowImage {
  max-width: 250px;
  padding: 0 0 0 30px;
}

.processTable__rowForm {
  width: 250px;
  margin-right: 40px;
  padding-top: 20px;
}

.processTable__ribbon {
  height: 100%;
  width: 20px;
  position: absolute;
  background-color: red;
  z-index: 1;
  top: 0;
}

.processTable__ribbon.reactant {
  background: #b7eb8f;
}

.processTable__ribbon.product {
  background: #91d5ff;
}

.processTable__ribbon.byproduct {
  background: #ffe58f;
}

.processTable__ribbon.catalyst {
  background: #adc6ff;
}

.processTable__ribbon.solvent {
  background: #d3adf7;
}
