.custom-drawer-wrapper {
  height: 100%;
  width: 378px;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d,
    -12px 0 48px 16px #00000008;
  height: 100%;
  position: absolute;
  transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1),
    box-shadow 2s cubic-bezier(0.23, 1, 0.32, 1);
  border: 1px solid #ddd;
}

#drawer {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1000;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
}

#drawer .drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: #000000d9;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px 2px 0 0;
}

#drawer .ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
