.custom-sortable-handle {
  display: flex;
  margin-top: 40px;
}

.custom-sortable-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}

.sort-list-delete-icon {
  display: flex;
  margin-top: 45px;
  margin-right: 10px;
}

.explorePage__drawer .ant-drawer-content-wrapper {
  width: 1200px !important;
}