.custom-addtional-role {
  font-size: 12px;
  margin-right: 40px;
  margin-left: 4px;
}

.main-component-input {
  height: 25px;
  font-size: 12px;
  max-width: 60px !important;
  margin-left: 20px;
}

.gx-d-justify-right {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.additional-component-select {
  height: 25px;
  width: 100px;
  margin-right: 3px !important;
}

.additional-component-input {
  max-width: 100px;
}
