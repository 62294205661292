.ant-image-preview-wrap {
}

.ant-image-preview-img {
  background-color: #fff;
  opacity: 1;
  max-height: 80% !important;
}

ul li span.anticon.anticon-rotate-left.ant-image-preview-operations-icon,
ul li span.anticon.anticon-rotate-right.ant-image-preview-operations-icon {
  display: none !important;
}

.gx-card-full {
  box-shadow: none !important;
}

.width-fit-content {
  width: fit-content !important;
}
