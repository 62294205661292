.document-row {
}

.drag {
  border: 1px solid #eee;
  padding: 20px;

  color: #2484ff;
  text-align: center;
  cursor: pointer;
}

.document-row-table .ant-table-cell {
  padding: 4px !important;
}

.dzu-dropzone {
  margin-top: 20px;
  padding-bottom: 15px;
  padding-top: 10px;
}

.browseFiles {
  display: flex;
  width: 100%;
  justify-content: center;
}

.browserFiles span {
  text-align: center;
}

.document-form .ant-form-item-explain {
  position: absolute;
  right: 20px;
  top: 43px;
}

.edit-file-header {
  display: flex;
  flex-wrap: wrap;
}

.edit-file-header p.gx-text-warning {
  margin-top: 10px;
  margin-right: 10px;
}

.not-found-item {
  color: #7c3535;
}

.document__widget {
  max-width: 100%;
  margin-bottom: 10px;
}

.document__content {
  width: 100%;
}

.document__name {
  font-size: 12px;
  color: #000;
}

.ant-table-cell {
  font-size: 12px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.ant-table-cell::-webkit-scrollbar {
  display: none; /* Chrome */
}

.bg-disabled {
  color: #7c3535 !important;
}
