.processSearch__label {
  color: "#333";
}

.processSearch__content {
  display: flex;
  column-gap: 30px;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.processSearch__content .canvasWrapper {
  max-width: 50vw;
}
