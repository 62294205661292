.process-components {
  width: 100%;
  min-width: 30vw;
}

.process-components-header {
  background-color: #eee;
}

.compound-not-found {
  border-radius: 4px;
}

.compound-image-preview img {
  object-fit: contain;
}

.width-80 {
  width: 80%;
}

.register-process-table .ant-table-tbody .ant-table-cell {
  padding: 0 20px;
}

.register-process-table .ant-table-tbody .ant-table-cell .width-fit-content {
  margin: 0 !important;
}

.register-process-table
  .ant-table-tbody
  .ant-table-cell
  .width-fit-content
  .ant-card {
  margin-bottom: 0;
}

.register-process-table .ant-table-tbody .ant-table-cell .ant-form {
  padding: 10px 20px 0 20px !important;
}

.registerProcess-MainComponent {
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.registerProcess-MainComponent #chemdrawjs-container {
  max-width: 50vw;
  margin-right: 30px;
}

@media (max-width: 1440px) {
  .registerProcess-MainComponent {
    flex-wrap: wrap;
  }
}

.registerProcess__label label {
  color: #f5f5f5;
}
