.mainContainer {
}

#chemdrawjs-container {
  height: 70vh;
  width: 100%;
}

#chemdraw-widget {
  height: 300px !important;
}

.hidden {
  display: none;
}
