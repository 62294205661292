.not-found-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 130px;
  padding-top: 0;
}

.not-found-card-description {
  width: 100%;
  margin-top: -60px;
  margin-bottom: 0 !important;
}
