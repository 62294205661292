.wrapper {
  width: 776px;
}

.compound-select {
  min-width: 200px;
}

.gx-width-500 {
  width: 500px;
}

.rightContent {
  margin-left: 50px;
}

.drawing-result {
  min-width: 23vw;
}

.image-preview {
  cursor: zoom-in;
  width: fit-content !important;
}

.loader {
  position: absolute;
  top: 0;
  left: 50%;
}

.customLoader {
  margin-top: 20vh;
  margin-left: 15vw;
}

@media screen and (min-width: 1583px) {
  .customLoader {
    margin-left: 7vw;
  }
}

.ant-carousel .slick-slide img {
  display: inherit;
}

.unknown-structure-input-field {
  height: 40px;
}

.registerNewCompound-MainComponent {
  gap: 60px;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.registerNewCompound-MainComponent #chemdrawjs-container {
  /* max-width: 50vw; */
  margin-right: 30px;
}

.InputViewType {
  min-width: 50vw;
  margin-right: 30px;
}

.InputViewType .textarea {
  max-width: 90%;
}

.InputViewType .textarea textarea {
  min-height: 30vh;
}

.registerNewCompound__label label {
  color: #f5f5f5 !important;
}

.registerNewCompound__checkbox {
  background: #fff;
}

.registerNewCompound__checkbox .ant-switch-handle::before {
  background-color: #038fde;
}

.registerNewCompound__resizer {
  flex-grow: 0;
  flex-basis: 6px;
}

.registerNewCompound__resizer:hover {
  width: 3px;
  background-color: #000;
}
