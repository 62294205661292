.gx-search__box {
  margin-left: 20px;
  position: relative;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 5px;
}

.gx-search__box .ant-input input {
  background: #fff !important;
}

.gx-search-bar input[type="search"] {
  background: #fff;
}

.gx-search-bar input[type="search"]:focus {
  background: #fff;
}

.gx-search__box .gx-search-icon {
  color: #000;
  cursor: pointer;
}