.sidebar-menu-header {
  background-color: #fff !important;
}

.sidebar-menu-text {
  color: #003366 !important;
  border: 1px solid #003366;
  border-radius: 4px;
}

.sidebar-menu-text:hover {
  color: #fff !important;
}

.custom-layout {
  position: absolute;
  top: 30vh;
}

.ant-menu-inline .ant-menu-item.settings {
  display: table;
  min-height: 100% !important;
  position: absolute;
  top: 260px;
}

.ant-menu-inline .icon.icon-setting {
  display: none;
}

.gx-layout-sider-scrollbar {
  height: 100vh !important;
  overflow: visible !important;
}

.gx-layout-sider-scrollbar div {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.gx-layout-sider-scrollbar div::-webkit-scrollbar {
  display: none; /* Chrome */
}

.ant-tree-list-holder-inner {
  padding: 10px 0;
}

.ant-tree-switcher-noop {
  display: none;
}

.ant-tree-treenode-switcher-close {
  margin-left: 10px;
}

.tree-icon i {
  margin-right: 10px !important;
}

.tree-icon.active {
  color: #fa8c15;
}

.tree-icon.active i {
  color: #fa8c15;
  margin-right: 10px !important;
}

.icon {
  height: 20px;
  width: 20px;
}

.icon:hover path,
.icon-active path {
  fill: #fa8c15 !important;
  stroke: #fa8c15 !important;
}

.icon-active-stroke path {
  stroke: #fa8c15 !important;
}

.info-icon path {
  color: white;
}
