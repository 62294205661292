.widget-card {
  /* height: fit-content !important; */
  max-height: 100%;
  /* min-height: 100%; */
  overflow-y: scroll;

  /* for internet explorer */
  -ms-overflow-style: none;
  /* firefox */
  scrollbar-width: none;

  position: relative;
}

/* remove side scrollbar for windows */
.widget-card::-webkit-scrollbar {
  /* display: none; */
}

.widget-card.resizable {
  height: 100% !important;
}

.widget-card.isOpen {
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #fff !important;
}

.widget-header {
  padding: 12px 24px !important;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
}

.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.widget-header-title {
  text-transform: unset !important;
}

.gx-width-400 {
}

.gx-width-150 {
  width: 150px !important;
}

.card-item {
  column-gap: 15px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding: 6px 0;
}

.card-item:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.toggle-button {
  cursor: pointer;
  display: inline-block;
}

.widget-body {
  padding-top: 0 !important;
  /* margin-top: 40px; */
}

.card-item-field {
  font-size: 12px;
  display: flex;
  white-space: pre-wrap;
}

.card-item-field span {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-item-field .icon {
  margin-left: 5px;
  cursor: pointer;
}

.card-item-value {
  font-size: 12px;
  color: #038fdd !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: end;
}

.half-width {
  max-width: 50%;
}

.trimmed {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.danger {
  color: red;
}

.success {
  color: green;
}

.ant-image img {
  /* height: fit-content !important; */
}

.detail-field-wrapper {
  display: flex;
  flex-direction: column;
}

.detail-field {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;
}

.detail-field .card-item-value {
  margin-left: 113px;
}

.gx-width-100 {
  width: 150px;
}

.modal-view {
  width: 100%;
}

.fullImageWrapper {
  display: block;
  position: relative;
  background-color: #fff;
}

.fullImageOverlay {
  position: absolute;
  display: flex;
  top: 50%;
  justify-content: center;
  width: 100%;
  font-size: 18px;
}

.racemateOverlay {
  position: absolute;
  display: flex;
  top: 80%;
  right: 20px;
  justify-content: flex-end;
  width: 100%;
  font-size: 14px;
}
