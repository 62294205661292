.home-page {
  flex-wrap: wrap;
  margin: 0 auto;
  column-gap: 25px;
}

@media only screen and (max-width: 600px) {
  .home-page {
    justify-content: center;
  }

  .homePage-carousel {
    max-width: unset;
    width: 100%;
    padding: 20px 15px 0;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.homePage-carousel-img {
  width: fit-content !important;
}

.slick-dots {
  text-align: center !important;
  bottom: 0px !important;
}

.sticky-header-component .ant-form-item-label > label {
  color: #fff !important;
}

.dataSources__checkboxGroup label {
  color: #fff;
}
