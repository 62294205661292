.react-resizable-handle-se {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: -41px;
  right: 1px;
  cursor: se-resize;
}

.react-resizable-handle-se::after {
  content: url("../../../assets/vendors/resizer.svg");
}

#content {
  width: 100%;
}
.react-grid-layout {
  height: 1920px !important;
  width: 1920px !important;
  overflow-y: scroll;
  background-color: #eee !important;
}

.edit-mode {
  background-image: url(assets/vendors/grid.png);
  background-repeat: repeat;
}

.layoutJSON {
  background: #ddd;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
  background: #ccc;
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #cce;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}
.toolbox__items {
  display: block;
}
.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}
.droppable-element {
  width: 100%;
  text-align: center;
  border: 1px solid #fdd;
  margin: 10px 0;
  padding: 10px;
}

.droppable-element:hover {
  cursor: grab;
}

.grid-layout-wrapper {
  height: fit-content;
  overflow-y: scroll;
  background-color: #fff;
  position: relative;
}

.grid-layout-header {
  margin-left: 10px;
  margin-top: 10px;
}
