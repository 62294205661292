.project-image {
    width: 300px !important;
    height: 100px !important;
}

@media (max-width: 768px) {
    .project-image {
        width: 300px !important;
        height: 100px !important;
    }
}