.widget-card {
  /* height: fit-content !important; */
  max-height: 100%;
  /* min-height: 100%; */
  overflow-y: scroll;

  /* for internet explorer */
  -ms-overflow-style: none;
  /* firefox */
  scrollbar-width: none;
  background-color: #fff !important;
}

/* remove side scrollbar for windows */
.widget-card::-webkit-scrollbar {
  display: none;
}

.widget-card.resizable {
  height: 100% !important;
  width: 100% !important;
  border-radius: 0;
}

.widget-card.isOpen {
}

.react-grid-item:not(.react-grid-placeholder) {
  background: #fff !important;
}

.widget-header {
  padding: 12px 24px !important;
}

.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.widget-header-title {
  text-transform: unset !important;
}

.gx-width-400 {
}

.gx-width-150 {
  width: 150px !important;
}

.card-item {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding: 6px 0;
  column-gap: 15px;
}

.card-item:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.toggle-button {
  cursor: pointer;
  display: inline-block;
}

.widget-body {
  padding-top: 0 !important;
  /* margin-top: 40px; */
  background-color: #fff;
}

.card-item-field {
  font-size: 12px;
  display: flex;
  white-space: pre-wrap;
}

.card-item-field span {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-item-field .icon {
  margin-left: 5px;
  cursor: pointer;
}

.card-item-value {
  font-size: 12px;
  color: #038fdd !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-item-value.full-width form.ant-form {
  margin-top: 10px !important;
  margin-right: 15px !important;
}

.card-item-value.full-width {
  width: 400px;
}

.card-item-value.full-width-tag {
  width: 417px;
}

.half-width {
  max-width: 50%;
}

.trimmed {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.danger {
  color: red;
}

.success {
  color: green;
}

.ant-image img {
  /* height: fit-content !important; */
}

.detail-field-wrapper {
  display: flex;
  flex-direction: column;
}

.detail-field {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;
}

.detail-field .card-item-value {
  margin-left: 113px;
}

.gx-width-100 {
  width: 150px;
}

.modal-view {
  width: 100%;
}
