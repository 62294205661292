.apply-data .ant-table-wrapper {
  overflow: scroll !important;
}

.apply-data td {
  max-width: 200px;
  max-height: 50px !important;
  height: 50px !important;
  white-space: nowrap;
  overflow: scroll;
}
