.compound-instance-widget .ant-table {
  font-size: 12px !important;
}

.compound-instance-widget .ant-table-body {
  -ms-overflow-style: auto !important;
  scrollbar-width: auto !important;
  overflow-y: unset !important;
}

.checkboxGroup {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.checkboxGroup .ant-checkbox-group-item {
  margin-bottom: 10px;
}

.compound-instance-widget-modal {
  display: flex;
  justify-content: space-between;
}

.compound-instance-widget-modal .left-content {
  display: flex;
  flex-wrap: nowrap;
}

.compound-instance-widget-modal .description-title {
  margin-top: 2px;
}

.compound-instance-widget-modal .close-icon {
  margin-right: 20px;
  z-index: 1000;
}