.italics {
  font-style: italic;
}

.superscript {
  vertical-align: super;
}

.ant-table-content {
  /* overflow: scroll; */
}

.ant-collapse-header {
  background-color: #fff;
}

.safetySentences__table table {
  font-size: 12px !important;
}

.safetySentences__table__parentRow {
  background-color: #e6f4ff !important;
}
