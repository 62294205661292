.wrap {
  overflow: hidden;
  border-radius: 3px;
  cursor: text;
  background: #fff;
  transition: all 0.3s;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding-top: 10px;
  padding-left: 10px;
}

.inputClass {
  background: none !important;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.ulClass {
  float: left;
  padding: 0;
  list-style: none;
  margin: 0;
}
.wrap:hover {
  border: 1px solid #8e8e8e;
}
.ant-tag {
  padding: 4px 7px;
  border: none;
  background: "#f3f3f3";
}
