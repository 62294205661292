.widget-container {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: masonry;
}

.widget-container-default {
  display: grid;
}

.image-preview .ant-card {
  padding: 10px 20px;
}

.header {
  justify-content: space-between;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

.compound-detail-left-content {
  flex-direction: column;
  row-gap: 0;
}

.compound-detail-left-content h3 {
  margin-bottom: 0;
}
