.full-image-carousel {
  padding: 0 24px;
  background-color: #fff;
  margin: 0 auto
}

.full-image-carousel .ant-carousel {
  display: flex;
justify-content: center;
flex-direction: column;
height: 100%;
}