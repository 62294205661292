.rowDropzoneArrowContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.rowDropzoneContainer {
  position: relative;
  display: block;

  background-color: #fff !important;
}

.rowDropzoneArrowContainer__title {
  padding: 8px 20px;
}

.rowDropzoneArrowContainer__arrow {
  padding: 8px 20px;
}
