.panel {
  position: fixed;
  top: 16px;
  left: 16px;
  z-index: 1000;
  min-width: 300px !important;
  min-height: 300px !important;
}

.panel__header {
  background-color: #003366;
  color: #fff !important;
  cursor: grab;
  padding: 12px 24px !important;
}

.panel__header:active {
  cursor: grabbing;
}

.panel__content {
  padding: 4px;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-bottom: 1px solid;
  border-radius: 6px;
}

.panel__header__content {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
}

.panel__header__right {
  cursor: pointer;
}

.panel__hidden {
  background-color: #003366 !important;
  position: fixed;
  padding: 18px 12px;
  color: #fff !important;
  bottom: -32px;
  right: 13px;
  width: 300px;
  height: 50px;
}
