.compound-instance-table table {
  max-width: 300px;
}

.compound-instance-table table .ant-table-thead .ant-table-cell {
  white-space: nowrap;
}

.compound-instance-table table .ant-table-tbody .ant-table-cell {
  white-space: nowrap;
  max-width: 400px;
  overflow: scroll;
}
