.compound-input-field-wrapper {
  flex-wrap: "nowrap" !important;
  display: "flex" !important;
  width: "100%" !important;
}

.compound-input-field-wrapper .title {
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}

.compound-input-field-wrapper .icon {
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}
